// import * as React from "react";
import React, { useState, useCallback } from "react";
import { scale, verticalScale, moderateScale } from "react-native-size-matters";
import { launchImageLibrary } from "react-native-image-picker";
// import { requestOneTimePayment, requestBillingAgreement } from 'react-native-paypal';
import { GiftedChat, IMessage } from "react-native-gifted-chat";
import DropDownPicker from "react-native-dropdown-picker";
import { View, Text, FlatList, StyleSheet, Image } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import axios from "axios";
import {
  MD3LightTheme as DefaultTheme,
  PaperProvider,
  List,
  TextInput,
  Button,
  IconButton,
  MD3Colors,
  Avatar,
  Card,
  Modal,
  Portal,
  ProgressBar,
  DataTable,
} from "react-native-paper";
import Test from "./test";

const Stack = createNativeStackNavigator();
const pages = [
  { name: "Login", title: "ログイン画面" },
  { name: "PassWord", title: "パスワードを再発行" },
  { name: "TeacherAdd", title: "会員登録・更新（先生）" },
  { name: "StudentAdd", title: "会員登録・更新（生徒）" },
  { name: "Contact", title: "問い合わせ" },
  { name: "Introduction", title: "紹介" },
  { name: "TeacherDash", title: "ダッシュボード(先生)" },
  { name: "Teacher", title: "先生詳細" },
  { name: "Search", title: "検索" },
  { name: "Search2", title: "検索条件" },
  { name: "Chat", title: "チャット" },
  { name: "PayPal", title: "決済" },
  { name: "Meet", title: "レッスン" },
  { name: "Evaluation", title: "評価" },
  { name: "History", title: "レッスン履歴" },
];
const theme = {
  ...DefaultTheme,
  dark: true,
  colors: {
    ...DefaultTheme.colors,
    primary: "green",
    // onPrimary: "green",
    primaryContainer: "green",
    onPrimaryContainer: "green",
    secondary: "green",
    background: "white",
    // secondary: "green",
    // onSecondary: "green",
    // secondaryContainer: "green",
    // onSecondaryContainer: "green",
    // tertiary: "green",
    // onTertiary: "green",
    // tertiaryContainer: "green",
    // onTertiaryContainer: "green",
    background: "white",
    // onBackground: "green",
    // surface: "green",
    // onSurface: "green",
    surfaceVariant: "white",
    onSurfaceVariant: "green",
    outline: "green",
    error: "green",
    onError: "green",
    errorContainer: "green",
    onErrorContainer: "green",
    surfaceDisabled: "green",
    onSurfaceDisabled: "green",
    shadow: "green",
    inverseOnSurface: "green",
    inverseSurface: "green",
    inversePrimary: "green",
    backdrop: "green",
  },
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
  },
  TextInput: {
    // marginTop: 10,
    fontSize: 25,
  },
  Button: {
    // marginTop: 10,
    fontSize: 25,
  },
});

export default App;

function App() {
  return (
    <PaperProvider theme={theme}>
      <NavigationContainer theme={theme}>
        <Stack.Navigator initialRouteName="Home">
          <Stack.Screen
            name="Home"
            component={Home}
            options={{ title: "画面一覧" }}
          />
          <Stack.Screen
            name="Login"
            component={Login}
            options={{ title: "ログイン" }}
          />
          <Stack.Screen
            name="PassWord"
            component={PassWord}
            options={{ title: "パスワードを再発行" }}
          />
          <Stack.Screen
            name="TeacherAdd"
            component={TeacherAdd}
            options={{ title: "会員登録・更新（先生）" }}
          />
                    <Stack.Screen
            name="Teacher"
            component={Teacher}
            options={{ title: "先生詳細" }}
          />
          <Stack.Screen
            name="TeacherDash"
            component={TeacherDash}
            options={{ title: "ダッシュボード（先生）" }}
          />
          <Stack.Screen
            name="Search"
            component={Search}
            options={{ title: "検索" }}
          />
                    <Stack.Screen
            name="Search2"
            component={Search2}
            options={{ title: "検索条件" }}
          />
          <Stack.Screen
            name="StudentAdd"
            component={StudentAdd}
            options={{ title: "会員登録・更新（生徒）" }}
          />
          <Stack.Screen
            name="Test"
            component={Test}
            options={{ title: "テスト" }}
          />
          <Stack.Screen
            name="Contact"
            component={Contact}
            options={{ title: "問い合わせ" }}
          />
          <Stack.Screen
            name="Introduction"
            component={Introduction}
            options={{ title: "紹介" }}
          />
          <Stack.Screen
            name="Chat"
            component={Chat}
            options={{ title: "チャット" }}
          />
          <Stack.Screen
            name="PayPal"
            component={PayPal}
            options={{ title: "決済" }}
          />
          <Stack.Screen
            name="Meet"
            component={Meet}
            options={{ title: "レッスン" }}
          />
          <Stack.Screen
            name="Evaluation"
            component={Evaluation}
            options={{ title: "評価" }}
          />
          <Stack.Screen
            name="History"
            component={History}
            options={{ title: "レッスン履歴" }}
          />
          {/* {pages.map((page) => {
            return (
              <Stack.Screen
                name={page.name}
                key={page.name}
                component={page.name}
                options={{ title: page.title }}
              />
            );
          })} */}
        </Stack.Navigator>
      </NavigationContainer>
    </PaperProvider>
  );
}
function Home({ navigation }) {
  return (
    <View style={styles.container}>
      {pages.map((page) => {
        return (
          <List.Item
            title={page.title}
            key={page.name}
            left={(props) => <List.Icon {...props} icon="folder" />}
            onPress={() => navigation.navigate(page.name)}
          />
        );
      })}

      {/* <FlatList
        data={pages}
        renderItem={({ page }) => (
          <List.Item
            title={page.title}
            left={(props) => <List.Icon {...props} icon="folder" />}
            onPress={() => navigation.navigate(page.name)}
          />
        )}
      /> */}
    </View>
  );
}
function Login() {
  return (
    <View style={styles.container}>
      <TextInput label="メールアドレス" style={styles.TextInput} />
      <TextInput label="パスワード" style={styles.TextInput} />
      <br />
      <Button
        icon="login"
        mode="contained"
        style={styles.Button}
        onPress={() => navigation.navigate("Home")}
      >
        ログイン
      </Button>
      <br />
      <Button
        compact
        mode="text"
        icon="account"
        onPress={() => navigation.navigate("Home")}
      >
        アカウントを作成
      </Button>
      <Button
        compact
        icon="account-alert"
        onPress={() => navigation.navigate("Home")}
      >
        パスワードを忘れた
      </Button>
    </View>
  );
}
function PassWord() {
  return (
    <View style={styles.container}>
      <TextInput label="メールアドレス" style={styles.TextInput} />
      <br />
      <Button
        icon="login"
        style={styles.Button}
        mode="contained"
        onPress={() => navigation.navigate("Home")}
      >
        メールを送信
      </Button>
    </View>
  );
}
function TeacherAdd() {
  const [openRegion, setOpenRegion] = useState(false);
  const [valueRegion, setValueRegion] = useState(null);
  const [region, setRegion] = useState([
    { label: "北海道", value: "北海道" },
    { label: "青森県", value: "青森県" },
    { label: "岩手県", value: "岩手県" },
    { label: "宮城県", value: "宮城県" },
    { label: "秋田県", value: "秋田県" },
    { label: "山形県", value: "山形県" },
    { label: "福島県", value: "福島県" },
    { label: "茨城県", value: "茨城県" },
    { label: "栃木県", value: "栃木県" },
    { label: "群馬県", value: "群馬県" },
    { label: "埼玉県", value: "埼玉県" },
    { label: "千葉県", value: "千葉県" },
    { label: "東京都", value: "東京都" },
    { label: "神奈川県", value: "神奈川県" },
    { label: "新潟県", value: "新潟県" },
    { label: "富山県", value: "富山県" },
    { label: "石川県", value: "石川県" },
    { label: "福井県", value: "福井県" },
    { label: "山梨県", value: "山梨県" },
    { label: "長野県", value: "長野県" },
    { label: "岐阜県", value: "岐阜県" },
    { label: "静岡県", value: "静岡県" },
    { label: "愛知県", value: "愛知県" },
    { label: "三重県", value: "三重県" },
    { label: "滋賀県", value: "滋賀県" },
    { label: "京都府", value: "京都府" },
    { label: "大阪府", value: "大阪府" },
    { label: "兵庫県", value: "兵庫県" },
    { label: "奈良県", value: "奈良県" },
    { label: "和歌山県", value: "和歌山県" },
    { label: "鳥取県", value: "鳥取県" },
    { label: "島根県", value: "島根県" },
    { label: "岡山県", value: "岡山県" },
    { label: "広島県", value: "広島県" },
    { label: "山口県", value: "山口県" },
    { label: "徳島県", value: "徳島県" },
    { label: "香川県", value: "香川県" },
    { label: "愛媛県", value: "愛媛県" },
    { label: "高知県", value: "高知県" },
    { label: "福岡県", value: "福岡県" },
    { label: "佐賀県", value: "佐賀県" },
    { label: "長崎県", value: "長崎県" },
    { label: "熊本県", value: "熊本県" },
    { label: "大分県", value: "大分県" },
    { label: "宮崎県", value: "宮崎県" },
    { label: "鹿児島県", value: "鹿児島県" },
    { label: "沖縄県", value: "沖縄県" },
  ]);
  const [openSex, setOpenSex] = useState(false);
  const [valueSex, setValueSex] = useState(null);
  const [sex, setSex] = useState([
    { label: "女", value: "女" },
    { label: "男", value: "男" },
    { label: "その他", value: "その他" },
  ]);
  const [openBank, setOpenBank] = useState(false);
  const [valueBank, setValueBank] = useState(null);
  const [bank, setBank] = useState([
    { label: "普通", value: "普通" },
    { label: "当座", value: "当座" },
    { label: "その他", value: "その他" },
  ]);
  return (
    <View style={styles.container}>
      <TextInput label="紹介コード" style={styles.TextInput} />
      <h1>あなたについて</h1>

      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
        <Image
          style={{ width: 170, height: 170 }}
          source={require("./assets/1.jpg")}
        />
        <div style={{ width: 8 }}></div>
        <Avatar.Image size={177} source={require("./assets/1.jpg")} />
      </View>

      <Button
        style={styles.Button}
        icon="camera"
        onPress={() => launchImageLibrary()}
      >
        写真を選択する
      </Button>
      <br />
      <TextInput label="メールアドレス" style={styles.TextInput} />
      <TextInput label="ニックネーム" style={styles.TextInput} />

      <TextInput label="生年月日　例：19851026" style={styles.TextInput} />
      <TextInput label="電話番号" style={styles.TextInput} />
      <TextInput
        multiline
        numberOfLines="10"
        label="プロフィール"
        style={styles.TextInput}
      />
      <br />
      <DropDownPicker
        open={openSex}
        placeholder="性別"
        autoScroll={true}
        value={valueSex}
        items={sex}
        setOpen={setOpenSex}
        setValue={setValueSex}
        setItems={setSex}
      />
      <br />
      <DropDownPicker
        open={openRegion}
        placeholder="活動拠点"
        zIndex={1000}
        autoScroll={true}
        value={valueRegion}
        items={region}
        setOpen={setOpenRegion}
        setValue={setValueRegion}
        setItems={setRegion}
      />
      <h1>ゴルフについて</h1>
      <TextInput label="得意なコース1" style={styles.TextInput} />
      <TextInput label="得意なコース2" style={styles.TextInput} />
      <TextInput label="得意なコース3" style={styles.TextInput} />
      <TextInput label="最高スコア" style={styles.TextInput} />
      <TextInput label="平均スコア" style={styles.TextInput} />
      <TextInput
        multiline
        numberOfLines="10"
        label="戦歴"
        style={styles.TextInput}
      />

      <h1>入金情報</h1>
      <TextInput label="インボイス番号" style={styles.TextInput} />
      <TextInput label="金融機関名" style={styles.TextInput} />
      <TextInput label="支店名" style={styles.TextInput} />
      <br />
      <DropDownPicker
        open={openBank}
        placeholder="口座種類"
        autoScroll={true}
        value={valueBank}
        items={bank}
        setOpen={setOpenBank}
        setValue={setValueBank}
        setItems={setBank}
      />
      <br />
      <TextInput label="口座番号" style={styles.TextInput} />

      <h1>その他</h1>
      <TextInput label="Googleトークン" style={styles.TextInput} />
      <br />
      <Button
        style={styles.Button}
        icon="login"
        mode="contained"
        onPress={() => navigation.navigate("Home")}
      >
        登録
      </Button>
    </View>
  );
}
function StudentAdd() {
  const [openSex, setOpenSex] = useState(false);
  const [valueSex, setValueSex] = useState(null);
  const [sex, setSex] = useState([
    { label: "女", value: "女" },
    { label: "男", value: "男" },
    { label: "その他", value: "その他" },
  ]);

  return (
    <View style={styles.container}>
      <TextInput label="紹介コード" style={styles.TextInput} />
      <TextInput label="メールアドレス" style={styles.TextInput} />
      <TextInput label="ニックネーム" style={styles.TextInput} />
      <TextInput label="電話番号" style={styles.TextInput} />

      <TextInput label="生まれ年　例：1985" style={styles.TextInput} />

      <br />
      <DropDownPicker
        open={openSex}
        placeholder="性別"
        autoScroll={true}
        value={valueSex}
        items={sex}
        setOpen={setOpenSex}
        setValue={setValueSex}
        setItems={setSex}
      />

      <br />
      <Button
        style={styles.Button}
        icon="login"
        mode="contained"
        onPress={() => navigation.navigate("Home")}
      >
        登録
      </Button>
    </View>
  );
}
function Contact() {
  return (
    <View style={styles.container}>
      <TextInput
        multiline
        numberOfLines="10"
        label="問い合わせ内容を入力"
        style={styles.TextInput}
      />
      <br />
      <Button
        icon="contacts"
        style={styles.Button}
        mode="contained"
        onPress={() => navigation.navigate("Home")}
      >
        送信
      </Button>
    </View>
  );
}
function Introduction() {
  return (
    <View style={styles.container}>
      <Image
        style={{ width: 355, height: 355 }}
        source={require("./assets/2.jpg")}
      />
      <p>紹介コード： 7df7dsa98f</p>
    </View>
  );
}
function TeacherDash() {
  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPageList] = React.useState([2, 3, 4]);
  const [itemsPerPage, onItemsPerPageChange] = React.useState(
    numberOfItemsPerPageList[0]
  );

  const [items] = React.useState([
    {
      key: 1,
      dateTime: "2023年10月24日 <br>15:00",
      student: "奥山 岳",
    },
    {
      key: 1,
      dateTime: "2023年10月24日 15:00",
      student: "奥山 岳",
      fat: 16,
    },
    {
      key: 1,
      dateTime: "2023年10月24日 15:00",
      student: "奥山 岳",
      fat: 16,
    },
    {
      key: 1,
      dateTime: "2023年10月24日 15:00",
      student: "奥山 岳",
      fat: 16,
    },
    {
      key: 1,
      dateTime: "2023年10月24日 15:00",
      student: "奥山 岳",
      fat: 16,
    },
    {
      key: 1,
      dateTime: "2023年10月24日 15:00",
      student: "奥山 岳",
      fat: 16,
    },
    {
      key: 1,
      dateTime: "2023年10月24日 15:00",
      student: "奥山 岳",
      fat: 16,
    },
    {
      key: 1,
      dateTime: "2023年10月24日 15:00",
      student: "奥山 岳",
      fat: 16,
    },
  ]);

  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, items.length);

  React.useEffect(() => {
    setPage(0);
  }, [itemsPerPage]);
  return (
    <View style={styles.container}>
      <h1>レッスン待ち一覧</h1>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>日時</DataTable.Title>
          <DataTable.Title>先生 / 生徒</DataTable.Title>
          <DataTable.Title></DataTable.Title>
        </DataTable.Header>

        {items.slice(from, to).map((item) => (
          <DataTable.Row key={item.key}>
            <DataTable.Cell>{item.dateTime}</DataTable.Cell>
            <DataTable.Cell>{item.student}</DataTable.Cell>
            <DataTable.Cell>
              <IconButton icon="chat" mode="outlined" />
              <IconButton icon="page-previous" Ï mode="outlined" />
            </DataTable.Cell>
          </DataTable.Row>
        ))}

        <DataTable.Pagination
          page={page}
          numberOfPages={Math.ceil(items.length / itemsPerPage)}
          onPageChange={(page) => setPage(page)}
          label={`${from + 1}-${to} of ${items.length}`}
          numberOfItemsPerPageList={numberOfItemsPerPageList}
          numberOfItemsPerPage={itemsPerPage}
          onItemsPerPageChange={onItemsPerPageChange}
          showFastPaginationControls
          selectPageDropdownLabel={"Rows per page"}
        />
      </DataTable>
      <h1>レッスン履歴</h1>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>日時</DataTable.Title>
          <DataTable.Title>先生 / 生徒</DataTable.Title>
          <DataTable.Title></DataTable.Title>
        </DataTable.Header>

        {items.slice(from, to).map((item) => (
          <DataTable.Row key={item.key}>
            <DataTable.Cell>{item.dateTime}</DataTable.Cell>
            <DataTable.Cell>{item.student}</DataTable.Cell>
            <DataTable.Cell>
              <IconButton icon="details" mode="outlined" />
            </DataTable.Cell>
          </DataTable.Row>
        ))}

        <DataTable.Pagination
          page={page}
          numberOfPages={Math.ceil(items.length / itemsPerPage)}
          onPageChange={(page) => setPage(page)}
          label={`${from + 1}-${to} of ${items.length}`}
          numberOfItemsPerPageList={numberOfItemsPerPageList}
          numberOfItemsPerPage={itemsPerPage}
          onItemsPerPageChange={onItemsPerPageChange}
          showFastPaginationControls
          selectPageDropdownLabel={"Rows per page"}
        />
      </DataTable>
    </View>
  );
}
function Chat() {
  const [messages, setMessages] = useState([]);

  const sendMessageToChatGPT = async (message) => {
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo-0301", // 使用するモデル
          messages: [
            { role: "system", content: "You" },
            { role: "user", content: message },
          ],
        },
        {
          headers: {
            Authorization: "Bearer {API KEY}",
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );
      // ChatGPTからの応答を取得
      const reply = response.data.choices[0].message.content;
      return reply;
    } catch (error) {
      console.error("ChatGPT API request error:", error);
      return null;
    }
  };

  const handleSend = useCallback(async (newMessages = []) => {
    const userMessage = newMessages[0];
    setMessages((previousMessages) =>
      GiftedChat.append(previousMessages, newMessages)
    );

    // ChatGPTにメッセージを送信して応答を受け取る
    const reply = await sendMessageToChatGPT(userMessage.text);

    if (reply) {
      const botMessage = {
        _id: Math.random().toString(),
        text: reply,
        createdAt: new Date(),
        user: { _id: 2, name: "ChatGPT" },
        avatar: "https://placeimg.com/140/140/any",
      };

      setMessages((previousMessages) =>
        GiftedChat.append(previousMessages, [botMessage])
      );
    }
  }, []);

  return (
    <View style={styles.container}>
      <GiftedChat messages={messages} onSend={handleSend} user={{ _id: 1 }} />
    </View>
  );
}

function PayPal() {
  return (
    <View style={styles.container}>
      <Image
        style={{ width: 355, height: 500 }}
        source={require("./assets/3.png")}
      />
    </View>
  );
}
function Meet() {
  return (
    <View style={styles.container}>
      <Image
        style={{ width: 355, height: 300 }}
        source={require("./assets/4.png")}
      />
      <br />
      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
        <IconButton icon="arrow-right-drop-circle" mode="outlined" />
        <IconButton icon="stop" mode="outlined" />
      </View>
      <br />
      <div>経過時間</div>
      <ProgressBar progress={0.5} />
    </View>
  );
}
function Evaluation() {
  const [openEva, setOpenEva] = useState(false);
  const [valueEva, setValueEva] = useState(null);
  const [eva, setEva] = useState([
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
  ]);
  return (
    <View style={styles.container}>
      <DropDownPicker
        open={openEva}
        placeholder="評価"
        autoScroll={true}
        value={valueEva}
        items={eva}
        setOpen={setOpenEva}
        setValue={setValueEva}
        setItems={setEva}
      />
      <br />
      <TextInput
        multiline
        numberOfLines="10"
        label="感想"
        style={styles.TextInput}
      />
      <br />
      <Button
        icon="login"
        mode="contained"
        style={styles.Button}
        onPress={() => navigation.navigate("Home")}
      >
        完了
      </Button>
    </View>
  );
}
function History() {
  const LeftContent = props => <Avatar.Icon {...props} icon="infomation" />
  return (
    <View style={styles.container}>
      <Card>
        <Card.Title
          title="レッスン履歴"
          subtitle="2023年10月24日 15:00〜　53分間"
          left={LeftContent}
        />
        <Card.Content>
          <br />
        </Card.Content>
        <Card.Title title="先生" subtitle="奥山 岳" left={LeftContent} />
        <Card.Content>
          <Text variant="titleLarge">生徒への評価： 4 / 5</Text>
          <Text variant="bodyMedium">よくがんばりました。</Text>
          <br />
        </Card.Content>
        <Card.Title title="生徒" subtitle="山田 太郎" left={LeftContent} />
        <Card.Content>
          <Text variant="titleLarge">先生への評価： 3 / 5</Text>
          <Text variant="bodyMedium">
            わかりやすかったです。わかりやすかったです。わかりやすかったです。わかりやすかったです。
          </Text>
        </Card.Content>
      </Card>
    </View>
  );
}
function Search() {
  const [visible, setVisible] = React.useState(false);

  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);
  const containerStyle = { backgroundColor: "white", padding: 20 };
  return (
    
    <View style={styles.container}>
              <Button style={{ marginTop: 30 }} onPress={showModal}
                      mode="contained">
          検索条件を指定
        </Button>

    <h1>先生一覧</h1>
      <Card>
        <Card.Cover
          style={{ height: 300 }}
          source={{ uri: "https://golpro.liplace.co.jp/1.jpg" }}
        />
        <Card.Title title="奥山 岳" subtitle="平均評価：4.3 / 5" />
        <Card.Content>
          <Text variant="titleLarge">
            プロフィールです。プロフィールです。プロフィールです。プロフィールです。プロフィールです。
          </Text>
        </Card.Content>
        <br />
        <Card.Actions>
          <Button mode="contained" style={styles.Button}>
            詳細
          </Button>
        </Card.Actions>
      </Card>
      <br />
      <Card>
        <Card.Cover
          style={{ height: 300 }}
          source={{ uri: "https://golpro.liplace.co.jp/1.jpg" }}
        />
        <Card.Title title="奥山 岳" subtitle="平均評価：4.3 / 5" />
        <Card.Content>
          <Text variant="titleLarge">
            プロフィールです。プロフィールです。プロフィールです。プロフィールです。プロフィールです。
          </Text>
        </Card.Content>
        <br />
        <Card.Actions>
          <Button mode="contained" style={styles.Button}>
            詳細
          </Button>
        </Card.Actions>
      </Card>
      <br />
      <Card>
        <Card.Cover
          style={{ height: 300 }}
          source={{ uri: "https://golpro.liplace.co.jp/1.jpg" }}
        />
        <Card.Title title="奥山 岳" subtitle="平均評価：4.3 / 5" />
        <Card.Content>
          <Text variant="titleLarge">
            プロフィールです。プロフィールです。プロフィールです。プロフィールです。プロフィールです。
          </Text>
        </Card.Content>
        <br />
        <Card.Actions>
          <Button mode="contained" style={styles.Button}>
            詳細
          </Button>
        </Card.Actions>
      </Card>
      <br />
      <Card>
        <Card.Cover
          style={{ height: 300 }}
          source={{ uri: "https://golpro.liplace.co.jp/1.jpg" }}
        />
        <Card.Title title="奥山 岳" subtitle="平均評価：4.3 / 5" />
        <Card.Content>
          <Text variant="titleLarge">
            プロフィールです。プロフィールです。プロフィールです。プロフィールです。プロフィールです。
          </Text>
        </Card.Content>
        <br />
        <Card.Actions>
          <Button mode="contained" style={styles.Button}>
            詳細
          </Button>
        </Card.Actions>
      </Card>
      <br />
      <Card>
        <Card.Cover
          style={{ height: 300 }}
          source={{ uri: "https://golpro.liplace.co.jp/1.jpg" }}
        />
        <Card.Title title="奥山 岳" subtitle="平均評価：4.3 / 5" />
        <Card.Content>
          <Text variant="titleLarge">
            プロフィールです。プロフィールです。プロフィールです。プロフィールです。プロフィールです。
          </Text>
        </Card.Content>
        <Card.Actions>
          <Button mode="contained" style={styles.Button}>
            詳細
          </Button>
        </Card.Actions>
      </Card>
      <br />
      <Card>
        <Card.Cover
          style={{ height: 300 }}
          source={{ uri: "https://golpro.liplace.co.jp/1.jpg" }}
        />
        <Card.Title title="奥山 岳" subtitle="平均評価：4.3 / 5" />
        <Card.Content>
          <Text variant="titleLarge">
            プロフィールです。プロフィールです。プロフィールです。プロフィールです。プロフィールです。
          </Text>
        </Card.Content>
        <Card.Actions>
          <Button mode="contained" style={styles.Button}>
            詳細
          </Button>
        </Card.Actions>
      </Card>
      <br />
      <br />
    </View>
  );
}
function Search2() {
  const [openSex, setOpenSex] = useState(false);
  const [valueSex, setValueSex] = useState(null);
  const [sex, setSex] = useState([
    { label: "女", value: "女" },
    { label: "男", value: "男" },
    { label: "その他", value: "その他" },
  ]);
  const [openRegion, setOpenRegion] = useState(false);
  const [valueRegion, setValueRegion] = useState(null);
  const [region, setRegion] = useState([
    { label: "北海道", value: "北海道" },
    { label: "青森県", value: "青森県" },
    { label: "岩手県", value: "岩手県" },
    { label: "宮城県", value: "宮城県" },
    { label: "秋田県", value: "秋田県" },
    { label: "山形県", value: "山形県" },
    { label: "福島県", value: "福島県" },
    { label: "茨城県", value: "茨城県" },
    { label: "栃木県", value: "栃木県" },
    { label: "群馬県", value: "群馬県" },
    { label: "埼玉県", value: "埼玉県" },
    { label: "千葉県", value: "千葉県" },
    { label: "東京都", value: "東京都" },
    { label: "神奈川県", value: "神奈川県" },
    { label: "新潟県", value: "新潟県" },
    { label: "富山県", value: "富山県" },
    { label: "石川県", value: "石川県" },
    { label: "福井県", value: "福井県" },
    { label: "山梨県", value: "山梨県" },
    { label: "長野県", value: "長野県" },
    { label: "岐阜県", value: "岐阜県" },
    { label: "静岡県", value: "静岡県" },
    { label: "愛知県", value: "愛知県" },
    { label: "三重県", value: "三重県" },
    { label: "滋賀県", value: "滋賀県" },
    { label: "京都府", value: "京都府" },
    { label: "大阪府", value: "大阪府" },
    { label: "兵庫県", value: "兵庫県" },
    { label: "奈良県", value: "奈良県" },
    { label: "和歌山県", value: "和歌山県" },
    { label: "鳥取県", value: "鳥取県" },
    { label: "島根県", value: "島根県" },
    { label: "岡山県", value: "岡山県" },
    { label: "広島県", value: "広島県" },
    { label: "山口県", value: "山口県" },
    { label: "徳島県", value: "徳島県" },
    { label: "香川県", value: "香川県" },
    { label: "愛媛県", value: "愛媛県" },
    { label: "高知県", value: "高知県" },
    { label: "福岡県", value: "福岡県" },
    { label: "佐賀県", value: "佐賀県" },
    { label: "長崎県", value: "長崎県" },
    { label: "熊本県", value: "熊本県" },
    { label: "大分県", value: "大分県" },
    { label: "宮崎県", value: "宮崎県" },
    { label: "鹿児島県", value: "鹿児島県" },
    { label: "沖縄県", value: "沖縄県" },
  ]);
  return (
    <View style={styles.container}>
      <TextInput label="フリーワード" style={styles.TextInput} />
      <br />
      <DropDownPicker
        open={openSex}
        placeholder="性別"
        autoScroll={true}
        value={valueSex}
        items={sex}
        setOpen={setOpenSex}
        setValue={setValueSex}
        setItems={setSex}
      />
<br />
<DropDownPicker
        open={openRegion}
        placeholder="活動拠点"
        zIndex={1000}
        autoScroll={true}
        value={valueRegion}
        items={region}
        setOpen={setOpenRegion}
        setValue={setValueRegion}
        setItems={setRegion}
      />
      <br />
<Button
        icon="login"
        mode="contained"
        style={styles.Button}
        onPress={() => navigation.navigate("Home")}
      >
        検索
      </Button>
    </View>
  );
}
function Teacher() {
  const [visible, setVisible] = React.useState(false);

  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);
  const containerStyle = { backgroundColor: "white", padding: 20 };
  return (
    
    <View style={styles.container}>


    <h1>奥山 岳先生の詳細</h1>
      <Card>
        <Card.Cover
          style={{ height: 300 }}
          source={{ uri: "https://golpro.liplace.co.jp/1.jpg" }}
        />

        <Card.Title  subtitle="平均評価：4.3 / 5" />
        <Card.Content>
          <Text variant="titleLarge">
            プロフィールです。プロフィールです。プロフィールです。プロフィールです。プロフィールです。
          </Text>
        </Card.Content>


      </Card>





    </View>
  );
}